<template>
  <div class="thank-you d-xl-flex justify-content-xl-end align-items-xl-center">
    <!-- Show for jobs -->
    <div v-if="jobs" class="job-request text-center text-xl-end">
      <img
        class="translate-xl-middle"
        src="@/assets/logo-mezcantina.png"
        alt="Logo mezcantina"
        width="150" />
      <div class="request-content px-4">
        <h1 class="headline mt-5 mb-2 mb-lg-3">¡Gracias por tu solicitud!</h1>
        <h2 class="headline mb-3 mb-lg-4">Te mantendremos al pendiente</h2>

        <router-link to="/" class="btn btn-primary">
          Volver a la mezcantina
        </router-link>
      </div>
      <picture>
        <source
          media="(min-width: 768px)"
          srcset="@/assets/images/png/shot-alacran.png" />
        <img
          src="@/assets/images/mobile/png/shot-alacran.png"
          alt="Shot alacran" />
      </picture>
    </div>
    <!-- Show for billing -->
    <div v-if="!jobs" class="billing-request w-100 text-center">
      <img
        class="translate-xl-middle"
        src="@/assets/logo-mezcantina.png"
        alt="Logo mezcantina"
        width="150" />
      <div class="request-content px-4">
        <h1 class="headline mt-5 mb-2 mb-lg-3">¡Hemos enviado tu solicitud!</h1>
        <h2 class="headline mb-5">para la elaboración de tu factura</h2>
        <h3 class="subtitle mb-3 mb-lg-4">
          Llegará a tu correo en cuanto esté lista<br />¡Muchas gracias!
        </h3>

        <router-link to="/" class="btn btn-primary">
          Volver a la mezcantina
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ThankYou',
    created() {},
    data() {
      history.pushState(null, "", "thank-you")
      return {};
    },
    props: {
      jobs: Boolean,
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .subtitle {
    font-size: $ft-lg;
  }
  h2.headline {
    color: $light;
  }
  .thank-you {
    height: 100vh;
    max-width: 1200px;
    margin: auto;
    padding-top: 50px;
    overflow: hidden;
    img[alt='Logo mezcantina'] {
      margin-bottom: 50px;
    }
    img[alt='Shot alacran'] {
      width: 80%;
      margin-top: 100px;
    }
  }
  @media (min-width: 425px) {
    .thank-you {
      img[alt='Shot alacran'] {
        width: 70%;
      }
    }
  }
  @media (min-width: $tablet-width) {
    .thank-you {
      .billing-request {
        margin-top: 20%;
      }
      img[alt='Shot alacran'] {
        width: 40%;
      }
    }
  }
  @media (min-width: $desktop-width) {
    .thank-you {
      .job-request {
        h2.headline {
          font-size: 4.8rem;
        }
      }
      .billing-request {
        margin-top: 10%;
      }
    }
  }
  @media (min-width: 1200px) {
    .thank-you {
      position: relative;
      .job-request {
        img[alt='Logo mezcantina'] {
          position: absolute;
          bottom: 0;
          left: 50%;
        }
      }
      .billing-request {
        margin-top: 0;
      }
      img[alt='Shot alacran'] {
        width: 30%;
        position: absolute;
        left: 50px;
        top: 100px;
      }
    }
  }
</style>
