<template>
  <div
    class="expand-image modal fade"
    id="expandImage"
    tabindex="-1"
    aria-labelledby="expandImage"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="row">
            <div class="col d-flex justify-content-center">
              <img
                :src="require(`@/assets/images/${image}`)"
                alt="Ambiente Mezcantina" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ExpandImage',
    created() {},
    data() {
      return {};
    },
    props: {
      image: String,
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .modal {
    &-content {
      width: initial;
      background: transparent;
    }
    &-body img {
      height: auto;
      width: 100%;
      object-fit: contain;
    }
  }
  @media (min-width: 576px) {
    .modal {
      &-dialog {
        max-width: initial;
        margin: auto;
      }
      &-body {
        img {
          width: 80%;
        }
      }
    }
  }
</style>
