<template>
  <div class="container-fluid bg-dark text-white">
    <footer class="footer container pt-5">
      <div class="row pt-5 px-4">
        <div class="col-4">
          <h4>Acerca de</h4>
          <ul class="nav flex-column mt-3">
            <li class="nav-item mb-2">
              <a href="#hero" class="nav-link p-0 text-muted">Nosotros</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#sucursales" class="nav-link p-0 text-muted"
                >Sucursales</a
              >
            </li>
            <li class="nav-item mb-2">
              <a href="#shop" class="nav-link p-0 text-muted">Tienda online</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#invest" class="nav-link p-0 text-muted">Inversión</a>
            </li>
          </ul>
        </div>

        <div class="col-4">
          <h4>Sucursal</h4>
          <ul class="nav flex-column mt-3">
            <li class="nav-item mb-2">
              <a href="#menu" class="nav-link p-0 text-muted">Menú</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#reserve" class="nav-link p-0 text-muted">Reservar</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#billing" class="nav-link p-0 text-muted">Facturación</a>
            </li>
            <li class="nav-item mb-2">
              <a href="#jobs" class="nav-link p-0 text-muted">Empleos</a>
            </li>
          </ul>
        </div>

        <div class="col-4">
          <h4>Síguenos</h4>
          <ul class="nav flex-column mt-3">
            <li class="nav-item mb-2">
              <a
                href="https://www.facebook.com/lamezcantinamx"
                class="nav-link p-0 text-muted"
                >Facebook</a
              >
            </li>
            <li class="nav-item mb-2">
              <a
                href="https://www.instagram.com/lamezcantina/"
                class="nav-link p-0 text-muted"
                >Instagram</a
              >
            </li>
            <li class="nav-item mb-2">
              <a
                href="https://www.tiktok.com/@lamezcantina_mx"
                class="nav-link p-0 text-muted"
                >TikTok</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="text-center py-4 mt-4 border-top">
        <p>© {{ getYear() }} La Mezcantina. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    created() {},
    data() {
      return {};
    },
    props: {},
    methods: {
      getYear: function () {
        const d = new Date();
        return d.getFullYear();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .footer {
    max-width: 1200px;
    margin: auto;
    font-size: $ft-xxs;
    h4 {
      font-size: $ft-sm;
    }
  }
</style>
