<template>
  <div
    class="sucursales modal fade"
    tabindex="-1"
    aria-labelledby="sucursalesModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Header Modal -->
        <div class="modal-header justify-content-center">
          <h5 class="modal-title subtitle text-center">
            Selecciona tu sucursal
          </h5>
          <button
            type="button"
            class="position-absolute d-none d-lg-inline-block"
            data-bs-dismiss="modal"
            aria-label="Close">
            <span class="icon-cancel"></span>
          </button>
        </div>
        <div class="modal-body">
          <!-- When click on menu, reserve, jobs, billing and don't sucursal selected yet -->
          <div class="row pt-3 justify-content-center" v-if="sucursalLinks">
            <div
              v-for="(sucursal, index) in sucursales"
              :key="index"
              class="col-6 col-lg-3 px-lg-2 pb-4 pb-lg-0"
              data-bs-dismiss="modal"
              v-on:click="chooseSucursal(index)">
              <router-link
                v-if="section === 'jobs' || section === 'billing'"
                class="sucursal position-relative"
                :to="`${sucursal.links[section]}`">
                <picture>
                  <source
                    media="(min-width:758px)"
                    :srcset="require(`@/assets/images/${sucursal.img}`)" />
                  <img
                    :src="
                      require(`@/assets/images/mobile/square-${sucursal.img}`)
                    "
                    :alt="sucursal.alt" />
                </picture>
                <div
                  class="sucursal_name position-absolute top-50 start-50 translate-middle">
                  <h3 class="text-uppercase text-center">
                    {{ sucursal.name }}
                  </h3>
                </div>
              </router-link>
              <a
                v-if="section !== 'jobs' && section !== 'billing'"
                class="sucursal position-relative"
                rel="noopener"
                :href="sucursal.links[section]"
                target="_blank">
                <picture>
                  <source
                    media="(min-width:758px)"
                    :srcset="require(`@/assets/images/${sucursal.img}`)" />
                  <img
                    :src="
                      require(`@/assets/images/mobile/square-${sucursal.img}`)
                    "
                    :alt="sucursal.alt" />
                </picture>
                <div
                  class="sucursal_name position-absolute top-50 start-50 translate-middle">
                  <h3 class="text-uppercase text-center">
                    {{ sucursal.name }}
                  </h3>
                </div>
              </a>
            </div>
          </div>
          <!-- Select Sucursal -->
          <div class="row pt-3 justify-content-center" v-else>
            <div
              v-for="(sucursal, index) in sucursales"
              :key="index"
              class="col-6 col-lg-3 px-lg-2 pb-4 pb-lg-0"
              data-bs-dismiss="modal"
              v-on:click="chooseSucursal(index)">
              <div class="sucursal position-relative">
                <picture>
                  <source
                    media="(min-width:758px)"
                    :srcset="require(`@/assets/images/${sucursal.img}`)" />
                  <img
                    :src="
                      require(`@/assets/images/mobile/square-${sucursal.img}`)
                    "
                    :alt="sucursal.alt" />
                </picture>
                <div
                  class="sucursal_name position-absolute top-50 start-50 translate-middle">
                  <h3 class="text-uppercase text-center">
                    {{ sucursal.name }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary text-uppercase"
            data-bs-dismiss="modal">
            Decidir en otro momento
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SucursalesModal',
  created() {},
  computed: {
    currentRouteName() {
      return this.$route.path;
    },
  },
  data() {
    return {};
  },
  props: {
    sucursales: Array,
    sucursalLinks: Boolean,
    section: String,
  },
  methods: {
    chooseSucursal: function (sucursal) {
      this.$emit('click', sucursal);
    },
  },
};
</script>

<style lang="scss" scoped>
.sucursales {
  .btn {
    padding: 8px 36px;
  }
  .headline,
  a {
    color: $light;
  }
  .sucursal {
    cursor: pointer;
    &:hover {
      color: $primary;
    }
    img {
      border-radius: 5%;
      width: 100%;
    }
    &_name {
      width: 100%;
      h3 {
        @include font-setup($headline-font, $ft-xs, $letter-spacing);
      }
      span {
        @include font-setup($headline-font, $ft-xxs, $letter-spacing);
      }
    }
  }
  .modal {
    &-header span {
      font-size: $ft-xl;
    }
    &-content {
      padding: 20px;
      background: $dark;
    }
    &-body a {
      display: block;
    }
    &-header {
      border: 0;
      button {
        background: none;
        border: 0;
        top: 20px;
        right: 20px;
      }
    }
    &-footer {
      border: 0;
    }
  }
}
@media (min-width: 768px) {
  .sucursales {
    .modal {
      &-dialog {
        max-width: 1200px;
        width: 60%;
      }
      &-content {
        padding: 20px 50px;
      }
    }
  }
}
@media (min-width: $desktop-width) {
  .sucursales {
    .modal {
      &-dialog {
        width: 80%;
        h5 {
          font-size: $ft-xxl;
        }
      }
      &-content {
        padding: 50px;
      }
    }
    .sucursal {
      &_name {
        width: 100%;
        h3 {
          font-size: $ft-lg;
        }
        span {
          font-size: $ft-sm;
        }
      }
    }
  }
}
// @media (max-width: 992px) {
//   .sucursales {
//     .sucursal_name {
//       h3 {
//         font-size: 1.8rem;
//       }
//       span {
//         font-size: $fs-m-sm;
//       }
//     }
//     .modal {
//       &-content {
//         padding: 50px 20px;
//       }

//       &-header {
//         button {
//           width: 46px;
//           top: 10px;
//           right: 10px;
//         }
//       }
//     }
//   }
// }
</style>
