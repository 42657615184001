<template>
  <div class="sucursales" id="sucursales">
    <div class="container text-center">
      <h2 class="headline">Nuestras Sucursales</h2>
      <div
        class="row justify-content-center col-md-8 offset-md-2 col-lg-10 offset-lg-1 col-xl-12 offset-xl-0 pt-lg-5 px-4 px-lg-0 mt-5">
        <div
          v-for="(sucursal, index) in sucursales"
          :key="index"
          class="col-6 col-lg-3 pb-5 px-2"
          data-bs-toggle="modal"
          data-bs-target="#sucursalDetails">
          <div
            class="sucursal position-relative"
            v-on:click="setupModal(index)">
            <picture>
              <source
                media="(min-width:758px)"
                :srcset="require(`@/assets/images/${sucursal.img}`)" />
              <img
                :src="require(`@/assets/images/mobile/square-${sucursal.img}`)"
                :alt="sucursal.alt" />
            </picture>
            <div
              class="sucursal-name position-absolute text-uppercase top-50 start-50 translate-middle">
              <h3 class="">{{ sucursal.name }}</h3>
              <span class="opacity-75">{{ sucursal.state }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DetailsModal :sucursal="sucursales[choosed]" />
  </div>
</template>

<script>
import DetailsModal from '@/components/sections/Sucursales/DetailsModal.vue';
export default {
  name: 'Sucursales',
  components: {
    DetailsModal,
  },
  created() {},
  data() {
    return {
      choosed: '0',
    };
  },
  props: {
    sucursales: Array,
  },
  methods: {
    setupModal: function (index) {
      this.choosed = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.sucursales {
  max-width: 1200px;
  padding-top: 90px;
  margin: auto;
  .headline {
    color: $light;
  }
  .sucursal {
    cursor: pointer;
    img {
      width: 100%;
      padding: 0 5%;
    }
    &-name {
      width: 100%;
      h3 {
        font-size: $ft-sm;
      }
      span {
        font-family: $headline-font;
        font-size: $ft-xxs;
      }
    }
  }
}
@media (min-width: $desktop-width) {
  .sucursales {
    padding-top: 200px;
    .sucursal {
      img {
        padding: 0;
      }
      &-name {
        width: 100%;
        h3 {
          font-size: $ft-lg;
        }
        span {
          font-family: $headline-font;
          font-size: $ft-sm;
        }
      }
    }
  }
}
</style>
