<template>
  <div class="home">
    <Header
      :sucursal="
        sucursales[choosedSucursal]
          ? `Suc. ${sucursales[choosedSucursal].name}`
          : 'Elige una sucursal'
      " />
    <div class="snake-bg"></div>
    <Hero />
    <Menu
      v-on:click="buttonClicked"
      :link="
        sucursales[choosedSucursal]
          ? sucursales[choosedSucursal].links.menu
          : ''
      " />
    <Sucursales :sucursales="sucursales" />
    <div class="snake-bg"></div>
    <Reserve
      v-on:click="buttonClicked"
      :link="
        sucursales[choosedSucursal]
          ? sucursales[choosedSucursal].links.reserve
          : ''
      " />
    <Slider />
    <Shop />
    <Invest />
    <div class="snake-bg"></div>
    <Jobs
      v-on:click="buttonClicked"
      :link="
        sucursales[choosedSucursal]
          ? sucursales[choosedSucursal].links.jobs
          : ''
      " />
    <Billing
      v-on:click="buttonClicked"
      :link="
        sucursales[choosedSucursal]
          ? sucursales[choosedSucursal].links.billing
          : ''
      " />
    <Contact />
    <Footer />
    <SucursalesModal
      :id="`sucursalesModal`"
      :sucursales="sucursales"
      v-on:click="chooseSucursal" />
    <SucursalesModal
      :id="`sucursalesLinks`"
      :sucursales="sucursales"
      :section="buttonClick"
      :sucursalLinks="true"
      v-on:click="chooseSucursal" />
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-5RBZMFM"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Hero from '@/components/sections/Hero.vue';
import Menu from '@/components/sections/Menu.vue';
import Sucursales from '@/components/sections/Sucursales/Sucursales.vue';
import Reserve from '@/components/sections/Reserve.vue';
import Slider from '@/components/sections/Slider.vue';
import Shop from '@/components/sections/Shop.vue';
import Invest from '@/components/sections/Invest.vue';
import Jobs from '@/components/sections/Jobs.vue';
import Billing from '@/components/sections/Billing.vue';
import Contact from '@/components/sections/Contact.vue';
import Footer from '@/components/Footer.vue';
import SucursalesModal from '@/components/sections/Sucursales/SucursalesModal.vue';

export default {
  name: 'Home',
  components: {
    Header,
    Hero,
    Menu,
    Sucursales,
    Reserve,
    Slider,
    Shop,
    Invest,
    Jobs,
    Billing,
    Contact,
    Footer,
    SucursalesModal,
  },
  methods: {
    chooseSucursal: function (sucursal) {
      this.choosedSucursal = sucursal;
    },
    buttonClicked: function (button) {
      console.log(button);
      this.buttonClick = button;
    },
    currentRouteName: function () {
      return this.$route.path;
    },
  },
  data() {
    return {
      choosedSucursal: '',
      buttonClick: '',
      sucursales: [
        {
          name: 'Tetlán',
          address: 'Calle Gigantes 4029, 44790',
          city: 'Guadalajara',
          state: 'Jalisco',
          phone: '33 2536 8221',
          mapImg: 'map-tetlan.jpg',
          img: 'sucursal-tetlan.jpg',
          alt: 'Sucursal Tetlán',
          links: {
            menu: 'https://menu.mindfood.com.mx/la-mezcantina-tetlan',
            reserve: 'https://reservations.yupoints.com/mezcantina_tetlan',
            jobs: '/jobs/form/tetlan',
            billing: '/billing/form/tetlan',
            maps: 'https://goo.gl/maps/FdDDfV2i9qeNgF4w6',
            facebook: 'https://www.facebook.com/lamezcantinatetlan',
          },
        },
        {
          name: 'Río Nilo',
          address: 'Av. Río Nilo 2329, La Paz 44860',
          city: 'Tlaquepaque',
          state: 'Jalisco',
          phone: '33 1879 0756',
          mapImg: 'map-rio-nilo.jpg',
          img: 'sucursal-rio-nilo.jpg',
          alt: 'Sucursal Rio Nilo',
          links: {
            menu: 'https://menu.mindfood.com.mx/la-mezcantina-rio-nilo',
            reserve: 'https://reservations.yupoints.com/mezcantina_rionilo',
            jobs: '/jobs/form/rio_nilo',
            billing: '/billing/form/rio_nilo',
            maps: 'https://goo.gl/maps/qRLEicsxWQGTjpbi6',
            facebook: 'https://www.facebook.com/lamezcantinarionilo',
          },
        },
        // {
        //   name: 'Manzanillo',
        //   address:
        //     'Av. Elías Zamora Verduzco, 2114-A, Valle de las Garzas 28219',
        //   city: 'Manzanillo',
        //   state: 'Colima',
        //   phone: '314 218 3768',
        //   mapImg: 'map-manzanillo.jpg',
        //   img: 'sucursal-manzanillo.jpg',
        //   alt: 'Sucursal Manzanillo',
        //   links: {
        //     menu: 'https://menu.mindfood.com.mx/la-mezcantina-manzanillo',
        //     reserve:
        //       'https://reservations.yupoints.com/mezcantina_manzanillo',
        //     jobs: '/jobs/form/manzanillo',
        //     billing: '/billing/form/manzanillo',
        //     maps: 'https://goo.gl/maps/83wQo2JekLuwZ8DN9',
        //     facebook: 'https://www.facebook.com/lamezcantinamanzanillo',
        //   },
        // },
        // {
        //   name: 'Lagos de Moreno',
        //   address:
        //     'Félix Ramírez Rentería 155-Loc 2, Pueblo de Moya, 47430',
        //   city: 'Lagos de moreno',
        //   state: 'Jalisco',
        //   phone: '474 742 3097',
        //   mapImg: 'map-lagos-de-moreno.jpg',
        //   img: 'sucursal-lagos-de-moreno.jpg',
        //   alt: 'Sucursal Lagos de Moreno',
        //   links: {
        //     menu: 'https://menu.mindfood.com.mx/la-mezcantina-lagos',
        //     reserve: 'http://m.me/lamezcantinalagos',
        //     jobs: '/jobs/form/lagos_de_moreno',
        //     billing: '/billing/form/lagos_de_moreno',
        //     maps: 'https://maps.app.goo.gl/aywwpjUyXxmVw94X9',
        //     facebook: 'https://www.facebook.com/lamezcantinalagos',
        //   },
        // },
      ],
    };
  },
};
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-5RBZMFM');
</script>

<style lang="scss">
.home {
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 4600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: $dark;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 4600px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    // opacity: .1;
    // background: url("../assets/images/noise-fondo.jpg"), #141414;
    // background-blend-mode: hard-light;
  }
  .snake-bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    background-image: url('../assets/images/png/serpiente.png');
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: multiply;
  }
  .header + .snake-bg {
    max-width: 650px;
    width: 80%;
    height: 165%;
    top: 25%;
    left: 50%;
    background-size: contain;
    transform: translateX(-50%) scaleX(-1);
  }
  .sucursales + .snake-bg {
    width: 70%;
    height: 150%;
    background-position-x: 97%;
    margin-top: -20%;
  }
  .invest + .snake-bg {
    width: 70%;
    height: 180%;
    right: 0;
  }
}
@media (min-width: $tablet-width) {
  .home {
    .header + .snake-bg {
      width: 50%;
    }
    .sucursales + .snake-bg {
      height: 100%;
    }
    .invest + .snake-bg {
      height: 100%;
    }
  }
}
@media (min-width: $desktop-width) {
  .home {
    &::before {
      height: 5600px;
    }
    .sucursales {
      & + .snake-bg {
        height: 250%;
        background-position-x: 100%;
      }
    }
    .invest {
      & + .snake-bg {
        height: 250%;
        background-position-x: -30%;
      }
    }
  }
}
@media (min-width: 1024px) and (orientation: portrait) {
  .home::before {
    height: 6600px;
  }
  .home {
    .sucursales + .snake-bg {
      height: 150%;
    }
    .invest + .snake-bg {
      height: 150%;
    }
  }
}
@media (min-width: 1280px) {
  .home::before {
    height: 6600px;
  }
}
@media (min-width: 1440px) {
  .home::before {
    height: 7000px;
  }
}
</style>
