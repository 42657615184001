<template>
  <section class="invest" id="invest">
    <div class="container-fluid px-md-5">
      <div class="row px-4">
        <!-- Text -->
        <div
          class="invest-info col-md-6 d-md-flex align-self-lg-center justify-content-lg-center">
          <div class="info align-self-md-center">
            <h2 class="headline">
              <span>invierte en la</span><br />
              mezcalería de méxico
            </h2>
            <div class="row">
              <p class="col-10 col-md-12 col-lg-10 mt-4">
                Conviértete en un empresario que promueve la cultura y tradición
                del mezcal.
              </p>
            </div>
            <a
              class="btn btn-primary mt-3 mt-lg-4"
              rel="noopener"
              href="https://inversion.lamezcantina.mx/"
              target="_blank"
              >Quiero invertir
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Invest',
    created() {},
    data() {
      return {};
    },
    props: {},
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .invest {
    padding: 100px 0;
    background: url('../../assets/images/invertir.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    mix-blend-mode: lighten;
  }
  @media (min-width: 375px) {
    .invest {
      background-position-x: 30px;
      background-position-y: 30px;
    }
  }
  @media (min-width: $tablet-width) {
    .invest {
      padding-top: 300px;
      margin-top: -25%;
    }
  }
  @media (min-width: $desktop-width) {
    .invest {
      padding-top: 250px;
      padding-bottom: 150px;
      margin-top: -15%;
      background-position-y: -100px;
      & > .container-fluid {
        max-width: 1200px;
      }
    }
  }
  @media (min-width: $desktop-lg-width) {
    .invest {
      padding-top: 450px;
      padding-bottom: 250px;
    }
  }
  @media (min-width: 1900px) {
    .invest {
      background-size: auto;
      margin-top: -10%;
      background-position: center;
    }
  }
</style>
