<template>
  <section class="shop" id="shop">
    <div class="container-fluid px-md-5">
      <div class="row justify-content-lg-around px-4 position-relative">
        <!-- Text -->
        <div
          class="shop-info col-md-6 offset-md-3 offset-lg-0 d-md-flex align-self-lg-center justify-content-lg-center text-center">
          <div class="info align-self-md-center">
            <h2 class="headline">
              <span>Llévate la experiencia</span><br />
              mezcalera a tu casa
            </h2>
            <div class="row shop-desc">
              <p class="mt-4">
                Ponemos a la venta tus productos favoritos de La Mezcantina.
              </p>
            </div>
            <a
              class="btn btn-primary mt-3 mt-lg-4"
              rel="noopener"
              href="https://lamezcalita.pedidosenlinea.mx/"
              target="_blank"
              >Tienda online
            </a>
            <div class="row justify-content-lg-center mt-5">
              <img
                class="logo col-md-2 offset-md-5 offset-lg-0 mt-lg-5 d-none d-md-block"
                src="@/assets/icons/logo-mezcantina.svg"
                alt="Logo Mezcantina"
                width="100" />
            </div>
          </div>
        </div>
        <!-- Image -->
        <div class="col-md-6 col-lg-5 mt-3 mt-lg-0 d-md-none">
          <img
            class="shop-img botella-1"
            src="@/assets/images/mobile/png/botella.png"
            alt="Botella de mezcal" />
        </div>
        <div class="col-md-6 col-lg-5 mt-3 mt-lg-0 d-md-none">
          <img
            class="shop-img botella-2"
            src="@/assets/images/mobile/png/botella.png"
            alt="Botella de mezcal" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Shop',
    created() {},
    data() {
      return {};
    },
    props: {},
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .shop {
    padding-top: 100px;
    padding-bottom: 50px;
    &-img {
      position: absolute;
      top: -40px;
      display: none;
    }
    .botella-1 {
      left: -24%;
    }
    .botella-2 {
      right: -22%;
    }
  }
  @media (min-width: 375px) {
    .shop {
      padding-bottom: 100px;
      &-desc {
        width: 90%;
        margin: auto;
      }
      &-img {
        display: block;
      }
    }
  }
  @media (min-width: 425px) {
    .shop {
      &-desc {
        width: 80%;
      }
      .botella-1 {
        left: -15%;
      }
      .botella-2 {
        right: -14%;
      }
    }
  }
  @media (min-width: $desktop-width) {
    .shop {
      padding-top: 250px;
    }
  }
</style>
