import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import JobsForm from '../views/JobsForm.vue';
import BillingForm from '../views/BillingForm.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/jobs/form',
    name: 'JobsForm',
    component: JobsForm,
    alias: [
      '/jobs/form/tetlan',
      '/jobs/form/rio_nilo',
      '/jobs/form/manzanillo',
      '/jobs/form/lagos_de_moreno',
      '/jobs/form/thank-you',
    ],
  },
  {
    path: '/billing/form',
    name: 'BillingForm',
    component: BillingForm,
    alias: [
      '/billing/form/tetlan',
      '/billing/form/rio_nilo',
      '/billing/form/manzanillo',
      '/billing/form/lagos_de_moreno',
      '/billing/form/thank-you',
    ],
  },
  {
    path: '*',
    name: '404',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
