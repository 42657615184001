<template>
  <div class="hero" id="hero">
    <div class="container-fluid">
      <div
        class="hero-content row justify-content-around align-items-md-center">
        <!-- Body -->
        <div
          class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-6 align-self-lg-center mt-md-5 pt-md-5 px-4 px-sm-5 px-lg-0">
          <div class="hero-title mt-3 mt-md-5 pt-md-5">
            <span class="overline">Somos</span>
            <h1 class="headline"><span>Somos</span> la mezcantina</h1>
          </div>
          <div class="hero-body row">
            <div class="col-md-8 col-lg-12">
              <p class="mt-4">
                Una cantina mexicana y restaurante que ofrece una experiencia
                mezcalera ancestral.
              </p>
              <p class="mt-4 mt-lg-5">
                Fusionamos los sabores y texturas más exóticos de Oaxaca,
                Durango, Guerrero y Jalisco para ofrecerte los mejores platillos
                y bebidas.
              </p>
              <h2 class="subtitle mt-lg-5 pt-4">
                Somos la mezcalería de México.
              </h2>
              <div class="row justify-content-center d-none d-md-flex">
                <img
                  class="hero_logo col-2 mt-5"
                  src="@/assets/icons/logo-mezcantina.svg"
                  alt="Logo mezcantina" />
              </div>
            </div>
          </div>
        </div>
        <!-- Image -->
        <div class="hero-img">
          <img src="@/assets/images/png/shot-alacran.png" alt="Shot alacran" />
          <img
            class="d-md-none"
            src="@/assets/images/png/nachos.png"
            alt="Nachos" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Hero',
    created() {},
    data() {
      return {};
    },
    props: {},
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .hero {
    position: relative;
    margin: auto;
    max-width: 1200px;
    height: 105vh;
    display: flex;
    align-items: center;
    &-title,
    &-body {
      .headline span {
        display: none;
      }
      text-align: center;
    }
    &-img {
      img {
        position: absolute;
      }
      [alt='Shot alacran'] {
        width: 35%;
        right: -10px;
        bottom: 20px;
        display: none;
      }
      [alt='Nachos'] {
        width: 105%;
        max-width: 105%;
        z-index: 1;
        bottom: -10px;
      }
    }
  }
  @media (min-width: 352px) {
    .hero {
      &-content {
        // padding-top: 50px;
        padding-bottom: 150px
      }
      &-title,
      &-body {
        .headline span {
          display: initial;
          color: $primary;
        }
        text-align: start;
      }
      &-body {
        width: 83%;
      }
      &-title {
        .overline {
          display: none;
        }
      }
      &-img {
        [alt='Shot alacran'] {
          display: block;
          right: -50px;
          bottom: 100px;
        }
        [alt='Nachos'] {
          bottom: 75px;
          left: -20px;
          right: -15px;
        }
      }
    }
  }
  @media (min-width: $tablet-width) {
    .hero > .container-fluid,
    .hero .hero-content {
      height: 100%;
    }
    .hero {
      height: 60vh;
      &-content {
        padding-top: 50px;
      }
      &-img {
        [alt='Shot alacran'] {
          width: 20%;
          right: 100px;
          bottom: 20%;
        }
      }
    }
  }
  @media (min-width: $desktop-width) {
    .hero {
      height: 105vh;
      &-img {
        [alt='Shot alacran'] {
          width: 30%;
          left: 125px;
          top: 180px;
          right: initial;
          bottom: initial;
        }
      }
    }
  }
</style>
