<template>
  <div class="jobs-form">
    <div v-if="!sentRequest" class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 px-5">
          <h1 class="headline text-center">Solicitud de empleo</h1>
          <form class="mt-5" action="" onsubmit="return false">
            <QuestionItem
              v-for="(question, index) in questions"
              :key="index"
              :index="index"
              :numQuestions="questions.length"
              :ejecutado="ejecutado"
              @change="validate"
              v-bind="question" />
            <label for="ticket" class="w-100">
              Adjuntar CV
              <div for="ticket" class="file-upload my-3 text-center">
                <span
                  class="d-flex justify-content-center align-items-center p-3">
                  <span class="icon-upload me-2"></span>
                  Adjuntar CV
                </span>
                <input
                  type="file"
                  class="form-control upload"
                  id="curriculum"
                  name="curriculum"
                  ref="curriculum"
                  accept=".jpg, .png, .pdf"
                  placeholder="Adjuntar ticket de consumo"
                  @change="saveFile" />
              </div>
            </label>
            <button
              type="submit"
              class="btn btn-primary w-100"
              @click="sendRequest">
              Enviar solicitud
            </button>
          </form>
        </div>
      </div>
    </div>
    <ThankYou v-if="sentRequest" :jobs="true" />
    <!-- Google Tag Manager (noscript) -->
    <noscript
      ><iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-5RBZMFM"
        height="0"
        width="0"
        style="display: none; visibility: hidden"></iframe
    ></noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
import QuestionItem from '@/components/Form/QuestionItem.vue';
import ThankYou from '@/components/Form/ThankYou.vue';
export default {
    name: 'JobsForm',
    components: {
        QuestionItem,
        ThankYou,
    },
    created() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    mounted() {
        let sucursal = this.$route.path.split('/')[3];
        // If title is different to JobsForm
        if (sucursal === 'thank-you') {
            location.pathname = '/';
        }
        if (localStorage.getItem('title') !== 'JobsForm') {
            localStorage.clear();
            console.log('localStorage was cleaned for JobsForm');
        }
        // Init form
        localStorage.setItem('title', 'JobsForm');
        localStorage.setItem('sucursal', sucursal);
        localStorage.setItem('validado', false);
    },
    data() {
        return {
            sentRequest: false,
            ejecutado: false,
            mailRight: false,
            phoneRight: false,
            file: '',
            questions: [
                {
                    name: 'sucursal',
                    label: 'Sucursal a aplicar',
                    type: 'list',
                    required: true,
                    placeholder: 'Selecciona la sucursal',
                    datalist: 'sucursales',
                    options: [
                        'Suc. Tetlán',
                        'Suc. Río Nilo',
                        'Suc. Manzanillo',
                        'Suc. Lagos de Moreno',
                    ],
                },
                {
                    name: 'job',
                    label: 'Puesto a aplicar',
                    type: 'list',
                    required: true,
                    placeholder: 'Selecciona un puesto de trabajo',
                    datalist: 'jobs',
                    options: [
                        'Ayudante de cocina',
                        'Chef',
                        'Lavaplatos',
                        'Hostess',
                        'Meseros',
                        'Bartender',
                        'Ayudante de barra',
                        'Cajero',
                        'Garrotero',
                        'Gerente Administrativo',
                        'Gerente Operativo',
                    ],
                },
                {
                    name: 'firstName',
                    label: 'Nombre',
                    type: 'text',
                    required: true,
                    placeholder: 'Nombre',
                },
                {
                    name: 'lastName',
                    label: 'Apellido',
                    type: 'text',
                    required: true,
                    placeholder: 'Apellido',
                },
                {
                    name: 'age',
                    label: 'Edad',
                    type: 'number',
                    required: true,
                    placeholder: 'Edad',
                },
                {
                    name: 'mail',
                    label: 'Correo',
                    type: 'mail',
                    required: true,
                    placeholder: 'Correo',
                },
                {
                    name: 'phone',
                    label: 'Teléfono',
                    type: 'number',
                    required: true,
                    placeholder: 'Teléfono',
                },
                {
                    name: 'city',
                    label: 'Ciudad',
                    type: 'text',
                    required: true,
                    placeholder: 'Ciudad',
                },
                {
                    name: 'workExperience',
                    label: 'Experiencia laboral',
                    type: 'textarea',
                    required: true,
                    placeholder: 'Experiencia laboral/Otros empleos',
                },
            ],
        };
    },
    props: {},
    methods: {
        saveFile: function () {
            this.file = this.$refs['curriculum'].files[0];
        },
        validate: function (name, condition) {
            // console.log(`Validate: ${name} is ${condition}`);
            if (name === 'mail') {
                this.mailRight = condition;
            } else if (name === 'phone') {
                this.phoneRight = condition;
            }
        },
        isMail: function (value) {
            const re = /\S+@\S+\.\S+/; // regex mail format
            return re.test(String(value).toLowerCase());
        },
        sendRequest: async function () {
            let answers = JSON.parse(localStorage.getItem('answers'));
            let dataToSend = {};
            dataToSend.title = localStorage.getItem('title');
            dataToSend.sucursal = localStorage.getItem('sucursal');
            dataToSend.answers = answers;

            if (answers !== null) {
                this.questions.forEach((question) => {
                    if (
                        question.required &&
                        Object.keys(answers).length === this.questions.length &&
                        answers[question.name] !== undefined
                    ) {
                        localStorage.setItem('validado', true);
                    } else if (
                        Object.keys(answers).length === this.questions.length ||
                        Object.keys(answers).length ===
                            this.questions.length - 1
                    ) {
                        localStorage.setItem('validado', true);
                    } else {
                        localStorage.setItem('validado', false);
                    }
                });
            }
            // console.log(`Form validated: ${localStorage.getItem('validado')}`);
            // Final check
            if (
                localStorage.getItem('validado') === 'true' &&
                this.isMail(answers.mail) &&
                answers.phone.length === 10
            ) {
                // Send dato to some API
                const axios = require('axios');
                let formData = new FormData();
                formData.append('data', JSON.stringify(dataToSend));
                formData.append('file', this.file);

                axios
                    .post(
                        'https://api.home.lamezcantina.mx/jobs/create',
                        formData
                    )
                    // .post('http://localhost:2302/jobs/create', formData)
                    .then((result) => {
                        console.log(result);
                        localStorage.clear();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                this.sentRequest = true;
                // console.log('Esta validado');
            } else {
                this.ejecutado = true;
            }
        },
    },
};

(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-5RBZMFM');
</script>

<style lang="scss" scoped>
.jobs-form .container {
  padding: 100px 0;
}

.file-upload {
  position: relative;
  overflow: hidden;
  width: 100%;
  & > span {
    background: transparent;
    width: 100%;
    font-size: 1.8rem;
    border: 3px solid $primary;
    border-radius: $border-radius;
    line-height: 1;
    span {
      font-size: 2.2rem;
    }
  }
  .upload {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
</style>
