<template>
  <div class="mb-3">
    <label :for="name" class="form-label"
      >{{ required ? label + ' *' : label }}
      <!-- Normal input -->
      <input
        v-if="
          this.type !== 'list' &&
          this.type !== 'textarea' &&
          this.type !== 'file'
        "
        :type="type"
        class="form-control"
        :class="required && ejecutado && value === '' ? 'required-field' : ''"
        :id="name"
        :name="name"
        :placeholder="placeholder"
        @change="saveAnswer"
        v-model="value" />
      <!-- Datalist input -->
      <select
        v-if="this.type === 'list'"
        class="form-control"
        :class="required && ejecutado && value === '' ? 'required-field' : ''"
        :id="name"
        :name="name"
        :ref="name"
        autocomplete="off"
        @change="saveOption"
        v-model="value">
        <option value="">{{ placeholder }}</option>
        <option v-for="(option, index) in options" :key="index" :value="option">
          {{ option }}
        </option>
      </select>
      <!-- Textarea field -->
      <textarea
        v-if="this.type === 'textarea'"
        class="form-control"
        :class="required && ejecutado && value === '' ? 'required-field' : ''"
        :id="name"
        :name="name"
        :placeholder="placeholder"
        @change="saveAnswer"
        v-model="value"
        rows="5"></textarea>
      <!-- File input -->
      <div v-if="this.type === 'file'" class="file-upload my-3 text-center">
        <span class="d-flex justify-content-center align-items-center p-3"
          ><span class="icon-upload me-2"></span>
          {{ /*value !== '' ? value : placeholder*/ placeholder }}</span
        >
        <input
          :type="type"
          class="form-control upload"
          :id="name"
          :name="name"
          :ref="name"
          accept=".jpg, .png, .pdf"
          :placeholder="placeholder"
          @change="saveFile"
          v-model="value" />
      </div>
    </label>
    <div v-if="required && ejecutado" class="text-error text-end">
      {{ errMsg() }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'QuestionItem',
    created() {},
    data() {
      return {
        value: '',
      };
    },
    props: {
      index: Number,
      name: String,
      label: String,
      type: String,
      required: Boolean,
      disabled: Boolean,
      placeholder: String,
      datalist: String,
      options: Array,
      numQuestions: Number,
      ejecutado: Boolean,
    },
    mounted() {
      let sucursal = this.$route.path.split('/')[3];
      let value = sucursal === 'tetlan' ? 'Suc. Tetlán'
      : sucursal === 'rio_nilo' ? 'Suc. Río Nilo'
      : sucursal === 'manzanillo' ? 'Suc. Manzanillo'
      : sucursal === 'lagos_de_moreno' ? 'Suc. Lagos de Moreno' : '';
      if (this.name === 'sucursal' ) {
        this.$refs.sucursal.value = value;
      }
      // Get data
      let answers = JSON.parse(localStorage.getItem('answers'));
      // Refill fields step 1
      if (localStorage.getItem('answers') !== null) {
        if (answers[`p${this.index}`] !== undefined) {
          this.value = answers[`p${this.index}`];
        }
      }
    },
    methods: {
      saveAnswer: function () {
        this.validate();
        let answers = {};
        // Create object answers into localStorage if no exist
        if (localStorage.getItem('answers') === null) {
          localStorage.setItem('answers', JSON.stringify(answers));
        }
        // Get current data of answers from localStorage
        answers = JSON.parse(localStorage.getItem('answers'));
        // Add new + old data
        answers[`${this.name}`] = `${this.value}`;
        // Save data in localStorage
        localStorage.setItem('answers', JSON.stringify(answers));
      },
      saveOption: function () {
        let answers = {};
        if (this.name === 'sucursal') {
          let value = this.value === 'Suc. Tetlán' ? 'tetlan' 
          : this.value === 'Suc. Río Nilo' ? 'rio_nilo'
          : this.value === 'Suc. Manzanillo' ? 'manzanillo'
          : 'lagos_de_moreno';
          localStorage.setItem('sucursal', value)
          } else {
          // Create object answers into localStorage if no exist
          if (localStorage.getItem('answers') === null) {
            localStorage.setItem('answers', JSON.stringify(answers));
          }
          // Get current data of answers from localStorage
          answers = JSON.parse(localStorage.getItem('answers'));
          // Add new + old data
          answers[`${this.name}`] = `${this.value}`;
          // Save data in localStorage
          localStorage.setItem('answers', JSON.stringify(answers));

        }
      },
      isMail: function (value) {
        const re = /\S+@\S+\.\S+/; // regex mail format
        return re.test(String(value).toLowerCase());
      },
      errMsg: function () {
        if (this.required) {
          return this.value === ''
            ? 'Campo obligatorio'
            : this.value.length !== 10 && this.name === 'phone'
            ? 'Deben ser 10 dígitos'
            : !this.isMail(this.value) && this.name === 'mail'
            ? 'Correo no valido'
            : '';
        }
      },
      validate: function () {
        if (this.name === 'mail') {
          this.$emit('change', this.name, this.isMail(this.value));
        } else if (this.name === 'phone') {
          this.$emit('change', this.name, this.value.length === 10);
        }
      },
      saveFile: function () {
        const file = this.$refs[`${this.name}`].files[0];
        this.$emit('change', file);

        let answers = {};
        // Create object answers into localStorage if no exist
        if (localStorage.getItem('answers') === null) {
          localStorage.setItem('answers', JSON.stringify(answers));
        }

        answers = JSON.parse(localStorage.getItem('answers'));
        answers[`${this.name}`] = "file upload";
        localStorage.setItem('answers', JSON.stringify(answers));
    },
    },
  };
</script>

<style lang="scss" scoped>
label {
  width: 100%;
  color: rgba($light, 0.75);
}
input,
textarea,
select {
  width: 100%;
  background: #47474a;
  font-size: $ft-sm;
  border-color: transparent;
  color: $light;
  margin-top: 5px;
  &:focus {
    background: #47474a;
    color: $light;
  }
}
input:disabled {
  background: #2c2c2e;
}
.required-field {
  border: 1px solid #f53535;
}
.text-error {
  font-size: $ft-xxs;
  color: $warning;
}
.file-upload {
  position: relative;
  overflow: hidden;
  & > span {
    background: $primary;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1;
    span {
      font-size: 2.2rem;
    }
  }
  .upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@media (min-width: $tablet-width) {
  input {
    height: 48px;
  }
  input,
  textarea {
    font-size: $ft-md;
  }
}
</style>
