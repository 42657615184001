<template>
  <section class="contact" id="contact">
    <div class="container-fluid px-md-5 px-lg-0">
      <div class="row justify-content-between px-4">
        <!-- Image -->
        <div class="col-12 col-md-6">
          <img
            class="contact-img position-relative"
            src="@/assets/images/contacto.jpg"
            alt="La Mezcantina" />
        </div>
        <!-- Text -->
        <div class="col-md-6 pt-5 align-self-center">
          <div class="col-md-10 offset-md-1 offset-lg-2">
            <h2 class="headline text-center text-md-start">Contáctanos</h2>
            <form class="mt-3" action="" onsubmit="return false">
              <label for="email">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="Email"
                  autocomplete="off"
                  v-model="email" />
              </label>
              <!-- <p v-if="email === ''" class="text-error">Campo requerido</p> -->
              <label for="phone">
                <input
                  type="number"
                  id="phone"
                  name="phone"
                  required
                  placeholder="Teléfono"
                  v-model="phone" />
              </label>
              <!-- <p v-if="phone.length != 10" class="text-error">
                El telefono deben ser 10 digitos
              </p> -->
              <label for="message">
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  required
                  placeholder="Mensaje"
                  autocomplete="off"
                  v-model="msg"></textarea>
              </label>
              <!-- <p v-if="msg === ''" class="text-error">Campo requerido</p> -->
              <button
                class="btn btn-primary my-3"
                type="submit"
                @click="checkFields">
                Enviar
              </button>
              <p
                v-if="(email === '' || phone === '' || msg === '') && ejecutado"
                class="text-error">
                Todos los campos son requeridos
              </p>
            </form>
            <ul class="row mt-5 text-center d-md-none">
              <p>Servicio a clientes:</p>
              <li>
                <a href="mailto: marketing@gmail.com"
                  ><span class="icon-mail4"></span>marketing@gmail.com</a
                >
              </li>
              <li>
                <a href="tel:3323121591"
                  ><span class="icon-phone"></span>33 2312 1591</a
                >
              </li>
              <li>
                <a href="https://www.facebook.com/lamezcantinamx"
                  ><span class="icon-facebook"></span> La Mezcantina</a
                >
              </li>
              <li>
                <a href="https://www.instagram.com/lamezcantina/"
                  ><span class="icon-instagram"></span> La Mezcantina</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Contact',
    created() {},
    data() {
      return {
        email: '',
        phone: '',
        msg: '',
        ejecutado: false,
      };
    },
    props: {},
    methods: {
      checkFields: function () {
        this.ejecutado = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .contact {
    max-width: 1200px;
    margin: auto;
    padding-top: 0;
    margin-top: -80%;
    .text-error {
      font-size: $ft-xxs;
      color: #f53535;
    }
    img {
      left: -100px;
      mix-blend-mode: lighten;
    }
    form {
      label {
        width: 100%;
      }
      input,
      textarea {
        width: 100%;
        color: $light;
        margin-bottom: 10px;
        background: transparent;
        border: 0;
        outline: none;
        border-bottom: 1px solid $light;
        &::placeholder {
          color: $light;
        }
        &:focus-visible {
          border-color: $primary;
        }
      }
      button {
        display: block;
        font-family: $headline-font;
        width: 100%;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        margin-bottom: 8px;
        a {
          text-decoration: none;
          color: $light;
          span {
            margin-right: 8px;
            font-size: 2rem;
          }
        }
      }
    }
  }
  @media (min-width: $tablet-width) {
    .contact {
      margin-top: 0;
      .text-error {
        font-size: $ft-sm;
      }
      &-img {
        max-width: 130%;
      }
      textarea {
        padding-top: 10px;
      }
    }
  }
</style>
