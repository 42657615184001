<template>
  <section class="reserve" id="reserve">
    <div class="container-fluid px-md-5">
      <div class="row justify-content-lg-around px-4">
        <!-- Text -->
        <div
          class="reserve-info col-md-6 d-md-flex align-self-lg-center justify-content-lg-center text-center">
          <div class="info align-self-md-center">
            <h2 class="headline">Haz tu reservación</h2>
            <div class="row">
              <p class="mt-4">
                ¡No hagas fila y aparta una mesa para que puedas festejar y
                divertirte sin esperar!
              </p>
              <p class="mt-4">
                Disfruta del mejor ambiente, los platillos más ricos y las
                bebidas más exóticas en tu Mezcantina favorita.
              </p>
            </div>
            <a
              v-if="link"
              class="btn btn-primary mt-3 mt-lg-4"
              rel="noopener"
              :href="link"
              target="_blank"
              >Reserva ahora
            </a>
            <button
              v-if="!link"
              class="btn btn-primary mt-lg-4"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#sucursalesLinks"
              v-on:click="buttonClicked('reserve')">
              Reserva ahora
            </button>
          </div>
        </div>
        <!-- Image -->
        <div class="col-md-6 col-lg-5 mt-3 mt-lg-0">
          <img
            class="reserve-img d-none d-md-block"
            src="@/assets/images/png/mezcalita.png"
            alt="Nachos" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Reserve',
    created() {},
    data() {
      return {};
    },
    props: {
      sucursal: String,
      link: String,
    },
    methods: {
      buttonClicked: function (button) {
        // console.log('Section log:' + button);
        this.$emit('click', button);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .reserve {
    padding-top: 70px;
    margin: auto;
    max-width: 1200px;
  }
  @media (min-width: 425px) {
    .reserve {
      &-info {
        .row {
          max-width: 320px;
          margin: auto;
        }
      }
    }
  }
  @media (min-width: $tablet-width) {
    .reserve {
      [alt='Nachos'] {
        max-width: 150%;
        right: -20%;
        position: relative;
      }
    }
  }
  @media (min-width: $desktop-width) {
    .reserve {
      padding-top: 10%;
      &-info {
        .row {
          max-width: 450px;
        }
      }
    }
  }
  @media (min-width: $desktop-lg-width) {
    .menu {
      margin-top: -10%;
      padding-top: 0;
      [alt='Nachos'] {
        max-width: 200%;
        left: -100%;
      }
    }
  }
</style>
