<template>
  <div
    class="sucursal-details modal fade"
    id="sucursalDetails"
    tabindex="-1"
    aria-labelledby="sucursalDetails"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-0 pb-4 py-lg-0">
          <div class="row justify-content-between">
            <div class="map col-12 col-lg-6 mb-5 mb-lg-0">
              <a rel="noopener" :href="sucursal.links.maps" target="_blank">
                <img
                  :src="require(`@/assets/images/${sucursal.mapImg}`)"
                  alt="Mapa de sucursal" />
              </a>
            </div>
            <div
              class="
                details
                col-10
                offset-1
                col-lg-6
                offset-lg-0
                d-flex
                flex-column
                justify-content-center
              ">
              <h3>{{ sucursal.name }}</h3>
              <strong class="mb-3 mb-lg-5"
                >{{ sucursal.city }} | {{ sucursal.state }}</strong
              >
              <a
                class="mb-3 mb-lg-5"
                rel="noopener"
                :href="sucursal.links.maps"
                target="_blank">
                {{ sucursal.address }}
              </a>
              <a
                class="mb-3 mb-lg-5"
                rel="noopener"
                :href="`tel:${sucursal.phone}`"
                target="_blank"
                ><span class="icon-phone"></span>
                <strong>{{ sucursal.phone }}</strong></a
              >
              <a
                class="mb-3 mb-lg-0"
                rel="noopener"
                :href="sucursal.links.facebook"
                target="_blank"
                ><span class="icon-facebook"></span>
                <strong>La Mezcantina {{ sucursal.name }}</strong></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DetailsModal',
    created() {},
    data() {
      return {};
    },
    props: {
      sucursal: Object,
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
.sucursal-details {
  .modal {
    &-dialog {
      width: 80%;
      margin: auto;
    }
    &-content {
      background: $dark;
      .map {
        img {
          border-radius: 5%;
        }
      }
      .details {
        font-size: $ft-sm;
        h3 {
          font-size: $ft-xl;
        }
        span {
          margin-right: 10px;
        }
        a {
          color: $light;
          text-decoration: none;
        }
      }
    }
  }
}
@media (min-width: $desktop-width) {
  .sucursal-details {
    .modal {
      &-dialog {
        max-width: 850px;
        .details {
          padding: 40px;
        }
      }
      &-content {
        .details {
          font-size: $ft-md;
          h3 {
            font-size: $ft-xxl;
          }
        }
      }
    }
  }
}
</style>
