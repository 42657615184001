<template>
  <header class="header position-fixed container-fluid p-0">
    <nav class="navbar navbar-expand-lg navbar-dark pb-0 px-lg-4">
      <div class="container-fluid p-0">
        <button
          class="navbar-toggler ps-4"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          ref="navToggler"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="icon-menu"></span>
        </button>
        <!-- Mobile logo -->
        <a class="navbar-brand d-lg-none p-3" href="/"
          ><img
            src="@/assets/icons/logo-mezcantina.svg"
            alt="Logo la mezcantina"
            width="36" />
        </a>
        <div
          class="collapse navbar-collapse justify-content-center text-center"
          id="navbarNav">
          <!-- Mobile header -->
          <ul class="navbar-nav text-uppercase d-lg-none align-items-lg-center">
            <li
              class="nav-item"
              v-for="(section, key, index) in sections"
              :key="index">
              <a class="nav-link" :href="`#${key}`" @click="closeNavbar">{{
                section
              }}</a>
            </li>
            <li class="nav-item">
              <button
                class="nav-link text-light bg-primary px-5 px-lg-2 py-lg-4 text-uppercase sucursal-select"
                type="button"
                ref="modalButton"
                data-bs-toggle="modal"
                data-bs-target="#sucursalesModal">
                {{ sucursal }}
              </button>
            </li>
          </ul>
          <!-- Desktop header -->
          <ul
            class="navbar-nav text-uppercase d-none d-lg-flex align-items-lg-center">
            <li class="nav-item">
              <router-link to="/" class="navbar-item logo-brand">
                <img
                  src="@/assets/icons/logo-mezcantina.svg"
                  alt="Logo la mezcantina"
                  width="40" />
              </router-link>
            </li>
            <li
              class="nav-item"
              v-for="(section, key, index) in sections"
              :key="index">
              <a class="nav-link" :href="`#${key}`">{{ section }}</a>
            </li>
            <li class="nav-item">
              <button
                class="nav-link text-light bg-primary px-5 px-lg-2 py-lg-4 text-uppercase sucursal-select"
                type="button"
                ref="modalButton"
                data-bs-toggle="modal"
                data-bs-target="#sucursalesModal">
                {{ sucursal }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  export default {
    name: 'Header',
    created() {},

    mounted() {
      this.$refs.modalButton.click();
    },
    data() {
      return {
        sections: {
          menu: 'Menú',
          sucursales: 'Sucursales',
          reserve: 'Reserva',
          shop: 'Tienda',
          invest: 'Inversión',
          jobs: 'Empleos',
          billing: 'Facturación',
          contact: 'Contacto',
        },
      };
    },
    props: {
      sucursal: String,
    },
    methods: {
      closeNavbar: function () {
        this.$refs.navToggler.click();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header {
    background: #020202;
    background-blend-mode: multiply;
    z-index: 10;
    .navbar {
      &-toggler {
        border: 0;
        &:focus {
          box-shadow: none;
        }
        .icon-menu {
          font-size: 29px;
        }
      }
      &-nav {
        .sucursal-select {
          width: 100%;
          border: 0;
        }
        .nav-link {
          font-size: $ft-sm;
          color: $light;
          padding: 10px 0;
          &:hover {
            background: $primary;
          }
        }
      }
    }
  }
  /** Desktop */
  @media (min-width: 992px) {
    .header {
      padding: 20px 0;
      font-family: $headline-font;
      font-size: 2.6rem;
      .navbar {
        &-collapse {
          margin: auto;
          .navbar-nav {
            width: 100%;
            justify-content: space-between;
          }
        }
        &-nav {
          .nav-link {
            margin: 0 15px;
            &:hover {
              background: none;
            }
          }
        }
      }
    }
  }
  @media (min-width: $desktop-lg-width) {
    .header {
      .navbar-collapse {
        max-width: 1200px;
      }
    }
  }
</style>
