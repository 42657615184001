<template>
  <section class="jobs" id="jobs">
    <div class="container-fluid pt-5">
      <div class="row justify-content-lg-between">
        <!-- Image -->
        <div class="col-md-6 col-lg-5 mt-3 mt-lg-0 p-0">
          <img
            class="jobs-img d-none d-md-block"
            src="@/assets/images/bolsa-de-trabajo.jpg"
            alt="Nachos" />
        </div>
        <!-- Text -->
        <div
          class="jobs-info col-md-6 d-flex justify-content-center align-items-md-center text-center">
          <div class="info">
            <h2 class="headline">Empleos</h2>
            <div class="row col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <p class="jobs-desc mt-4 mb-3">
                En la Mezcantina seguimos creciendo y buscamos gente como tú
                para unirse a nuestro equipo de trabajo ¡Deja aquí tu solicitud!
              </p>
            </div>
            <router-link
              v-if="link"
              class="btn btn-primary mt-3 mt-lg-4"
              :to="link"
              >Solicitud de empleo
            </router-link>
            <button
              v-if="!link"
              class="btn btn-primary mt-lg-4"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#sucursalesLinks"
              v-on:click="buttonClicked('jobs')">
              Solicitud de empleo
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Jobs',
    created() {},
    computed: {
      currentRouteName() {
        return this.$route.path;
      },
    },
    data() {
      return {};
    },
    props: {
      sucursal: String,
      link: String,
    },
    methods: {
      buttonClicked: function (button) {
        // console.log('Section log:' + button);
        this.$emit('click', button);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .jobs {
    margin: auto;
    margin-top: -100px;
    padding-top: 100px;
    max-width: 1200px;
    &-desc {
      width: 88%;
      margin: auto;
    }
    &-img {
      max-width: 100%;
      position: relative;
      left: -5%;
    }
  }
  @media (min-width: $desktop-width) {
    .jobs {
      &-desc {
        width: auto;
        margin: auto;
      }
      &-img {
        max-width: 150%;
        position: relative;
        left: -30%;
      }
    }
  }
  @media (min-width: $desktop-lg-width) {
    .jobs {
      &-img {
        left: -50%;
      }
    }
  }
</style>
