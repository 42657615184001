<template>
  <section class="billing" id="billing">
    <div class="container-fluid px-md-5">
      <div class="row px-4">
        <!-- Text -->
        <div
          class="billing-info col-md-6 d-md-flex align-self-lg-center justify-content-lg-center">
          <div class="info align-self-md-center">
            <h2 class="headline">Facturación</h2>
            <div class="row">
              <p class="col-10 col-md-12 col-lg-10 mt-4">
                ¿Quieres facturar tu consumo en La Mezcantina? Asegúrate de
                tener tu ticket a la mano.
              </p>
            </div>
            <router-link
              v-if="link"
              class="btn btn-primary mt-3 mt-lg-4"
              :to="link"
              >Solicitar factura
            </router-link>
            <button
              v-if="!link"
              class="btn btn-primary mt-lg-4"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#sucursalesLinks"
              v-on:click="buttonClicked('billing')">
              Solicitar factura
            </button>
          </div>
        </div>
        <!-- Image -->
        <div class="col-md-6 col-lg-5 mt-3 mt-lg-0">
          <img
            class="billing-img"
            src="@/assets/images/png/cata-mezcalera.png"
            alt="Nachos" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Billing',
    created() {},
    data() {
      return {};
    },
    props: {
      sucursal: String,
      link: String,
    },
    methods: {
      buttonClicked: function (button) {
        // console.log('Section log:' + button);
        this.$emit('click', button);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .billing {
    padding-top: 100px;
    max-width: 1200px;
    margin: auto;
    &-img {
      max-width: 200%;
      position: relative;
      right: -10%;
      top: -20%;
      z-index: -1;
    }
  }
  @media (min-width: 375px) {
    .billing {
      &-img {
        right: 0;
        top: -20%;
      }
    }
  }
  @media (min-width: $tablet-width) {
    .billing {
      padding-top: 0;
      &-img {
        max-width: 250%;
        right: 50%;
        top: 0;
      }
    }
  }
</style>
