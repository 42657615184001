<template>
  <div class="slider">
    <div
      id="carouselExampleControls"
      class="carousel slide d-md-none"
      data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          data-bs-toggle="modal"
          data-bs-target="#expandImage"
          class="carousel-item"
          :class="index != 0 ? '' : 'active'"
          v-for="(image, index) in images"
          :key="index"
          v-on:click="showImg(index)">
          <img
            :src="require(`@/assets/images/mobile/${image}`)"
            class="d-block w-100"
            :alt="`Ambiente mezcantina ${index + 1}`" />
        </div>
      </div>
    </div>
    <VueSlickCarousel :class="'d-none d-md-block'" v-bind="settings">
      <div
        data-bs-toggle="modal"
        data-bs-target="#expandImage"
        v-for="(image, index) in images"
        :key="index"
        v-on:click="showImg(index)">
        <img
          :src="require(`@/assets/images/${image}`)"
          alt="Ambiente Mezcantina" />
      </div>
    </VueSlickCarousel>
    <ExpandImage :image="images[img]" />
  </div>
</template>

<script>
  import ExpandImage from '@/components/ExpandImage.vue';
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
  export default {
    name: 'Slider',
    components: {
      VueSlickCarousel,
      ExpandImage,
    },
    created() {},
    data() {
      return {
        img: '0',
        images: ['ambiente-1.jpg', 'ambiente-2.jpg', 'ambiente-3.jpg'],
        settings: {
          centerMode: true,
          centerPadding: '20px',
          edgeFriction: 0.35,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          cssEase: 'linear',
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          pauseOnFocus: true,
          pauseOnHover: true,
        },
      };
    },
    props: {},
    methods: {
      showImg: function (index) {
        this.img = index;
      },
    },
  };
</script>

<style lang="scss">
  .slider {
    max-width: 100%;
    padding-top: 100px;
    .carousel-inner {
      height: 200px;
      .carousel-item {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .slick {
    &-slider {
      height: 100%;
    }
    &-list {
      height: 100%;
    }
    &-track {
      height: 100%;
    }
    &-slide div {
      height: 100%;
      padding: 0 5px;
      cursor: pointer;
      img {
        height: 100%;
      }
    }
  }
  @media (min-width: $tablet-width) {
    .slider {
      height: 400px;
    }
  }
</style>
