var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('Header',{attrs:{"sucursal":_vm.sucursales[_vm.choosedSucursal]
        ? ("Suc. " + (_vm.sucursales[_vm.choosedSucursal].name))
        : 'Elige una sucursal'}}),_c('div',{staticClass:"snake-bg"}),_c('Hero'),_c('Menu',{attrs:{"link":_vm.sucursales[_vm.choosedSucursal]
        ? _vm.sucursales[_vm.choosedSucursal].links.menu
        : ''},on:{"click":_vm.buttonClicked}}),_c('Sucursales',{attrs:{"sucursales":_vm.sucursales}}),_c('div',{staticClass:"snake-bg"}),_c('Reserve',{attrs:{"link":_vm.sucursales[_vm.choosedSucursal]
        ? _vm.sucursales[_vm.choosedSucursal].links.reserve
        : ''},on:{"click":_vm.buttonClicked}}),_c('Slider'),_c('Shop'),_c('Invest'),_c('div',{staticClass:"snake-bg"}),_c('Jobs',{attrs:{"link":_vm.sucursales[_vm.choosedSucursal]
        ? _vm.sucursales[_vm.choosedSucursal].links.jobs
        : ''},on:{"click":_vm.buttonClicked}}),_c('Billing',{attrs:{"link":_vm.sucursales[_vm.choosedSucursal]
        ? _vm.sucursales[_vm.choosedSucursal].links.billing
        : ''},on:{"click":_vm.buttonClicked}}),_c('Contact'),_c('Footer'),_c('SucursalesModal',{attrs:{"id":"sucursalesModal","sucursales":_vm.sucursales},on:{"click":_vm.chooseSucursal}}),_c('SucursalesModal',{attrs:{"id":"sucursalesLinks","sucursales":_vm.sucursales,"section":_vm.buttonClick,"sucursalLinks":true},on:{"click":_vm.chooseSucursal}}),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('noscript',[_c('iframe',{staticStyle:{"display":"none","visibility":"hidden"},attrs:{"src":"https://www.googletagmanager.com/ns.html?id=GTM-5RBZMFM","height":"0","width":"0"}})])}]

export { render, staticRenderFns }